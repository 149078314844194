import {createApp} from 'vue'
import {createInertiaApp} from '@inertiajs/vue3'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers'
import {rootApp} from "./bootstrap"
import InstantSearch from 'vue-instantsearch/vue3/es'
import { createPinia } from 'pinia';

const appName: string = 'App Name'

const pinia = createPinia()

createInertiaApp({
    title: (title) => title ? `${title} - ${appName}` : appName,
    resolve: (name) => <any>resolvePageComponent(`./pages/${name}.vue`, import.meta.glob('./pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp(rootApp(App,props))
        app.use(plugin)
        app.use(InstantSearch)
        app.use(pinia)
        app.mount(el)
    },
    progress: {
        color: '#4B5563'
    }
})
